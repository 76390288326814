import { template as template_5e97b435f2aa4dfc88df8aae256a8a71 } from "@ember/template-compiler";
const FKText = template_5e97b435f2aa4dfc88df8aae256a8a71(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
