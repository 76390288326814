import { template as template_9e2d869115454e219db67f1ef11f64b5 } from "@ember/template-compiler";
import LightDarkImg from "discourse/components/light-dark-img";
const CategoryLogo = template_9e2d869115454e219db67f1ef11f64b5(`
  <div class="category-logo aspect-image">
    <LightDarkImg
      @lightImg={{@category.uploaded_logo}}
      @darkImg={{@category.uploaded_logo_dark}}
    />
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default CategoryLogo;
