import { template as template_ab495cbff21b44368594b3c3cff4ba91 } from "@ember/template-compiler";
const FKControlMenuContainer = template_ab495cbff21b44368594b3c3cff4ba91(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
